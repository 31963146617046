window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  var btnnav = document.querySelector('.js-togglemenu');
  var mobilenav = document.querySelector('.js-navwrapper');

  btnnav.onclick = function () {
    btnnav.classList.toggle('active');
    mobilenav.classList.toggle('active');
  }

  /* show / hide timeline
  -------------------------------------------------- */
  const toggler = document.querySelectorAll('div.js-toggle-timeline');

  toggler.forEach((link) => {
    link.addEventListener('click', () => {
      link.classList.toggle('show');
    });
  });
};
